/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: Brown, Arial, sans-serif;

	--primary1: #1E1450;
	--primary2: #5154B6;

	--gradientStart: #352f8f;
	--gradientStop: #16061f;

	--accent1: #FF2261;
	--accent2: #FF3C14;
	--accent3: #00E68C;
	--accent4: #3230BE;
	--accent5: #EBB700;

	--textColor1: #FFF;
	--textColor2: #0f0f19;

	--background: #F9F9F9;
}

html, body {
	background: var(--background);
	min-height: 100%;
	height: 100%;
	color: var(--textColor1);
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--fontFamilyBase);
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
	font-weight: bold;
}

h1 {
	font-size: 44px;
	line-height: 46px;
}

h2 {
    font-size: 36px;
	line-height: 40px;
}

h3 {
    font-size: 28px;
	line-height: 32px;
}

h4 {
    font-size: 24px;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

/* Preload Landing images */
body::after{
	/* hide images */
	position: absolute;
	width:0;
	height:0;
	overflow:hidden;
	z-index:-1;

	/* load images */
	content: url("../img/bg-landing.jpg")
			url("../img/bg-landing-gradient.png")
			url("../img/bg-landing-mobile.jpg")
			url("../img/bg-landing-mobile-gradient.png")
			url("../img/bg-login.jpg")
			url("../img/bg-login-mobile.jpg")
			url("../img/bg-login-mobile-gradient.png")
			url("../img/bg-registration.jpg")
			url("../img/bg-registration-mobile.jpg")
			url("../img/bg-registration-mobile-gradient.png");
}
