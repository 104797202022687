@font-face {
    font-family: 'Brown';
    src: url('../fonts/Brown-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brown';
    src: url('../fonts/Brown-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brown';
    src: url('../fonts/Brown-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
